<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">{{$route.name}}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="card mb-4">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">Charts</h3>
        </div>
        <!-- Card body -->
        <div class="card-body">
          <!-- Form groups used in grid -->
          <div class="row">
            <div class="col-md-2">
              <div class="row" style="align-items: center; border-bottom: 1px solid rgba(0, 0, 0, 0.05); margin-bottom: 10px;">
                <div class="col-md-4">
                  <base-checkbox v-model="showAll" class="mb-3" @change="toggleShowAll">[100]</base-checkbox>
                </div>
                <div class="col-md-8">
                  <base-input input-classes="form-control-sm" placeholder="Search..."/>
                </div>
              </div>

              <template v-for="(data, index) in chartData.datasets">
                <base-checkbox :key="index" v-model="chartData.datasets[index].showLine" class="mb-3">
                  {{ data.label }}
                </base-checkbox>
              </template>
            </div>
            <div class="col-md-10">
              <line-chart :chart-data="chartData" :options="chartOptions"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from '@/views/Components/LineChart';
import _ from 'lodash';

export default {
  name: "Chart",
  components: {LineChart},
  data() {
    return {
      showAll: true,
      chartData: {
        labels: ["January","February","March","April","May","June","July"],
        backgroundColor: '#f87979',
        datasets:[
          {
            label: "Top 1",
            data: [1,2,4,5,3,1,3],
            fill: false,
            borderColor: "rgb(75, 192, 192)",
            lineTension: 0,
            showLine: true
          },
          {
            label: "Top 2",
            data: [2,1,2,3,2,2,6],
            fill: false,
            borderColor: "rgb(142, 33, 26)",
            lineTension: 0,
            showLine: true
          }
        ]
      },
      chartOptions: {
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              reverse: true,
              suggestedMin: 1,
              stepSize: 1
            }
          }]
        }
      }
    };
  },
  created() {
    this.fillData(this.chartData);
  },
  methods: {
    toggleShowAll() {
      if (this.showAll) {
        _.map(this.chartData.datasets, function(dataset) {
          dataset.showLine = true;
          return dataset;
        });

      } else {
        _.map(this.chartData.datasets, function(dataset) {
          dataset.showLine = false;
          return dataset;
        });
      }
    },
    fillData(data) {
      this.chartData = data;
    },
    getRandomInt () {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    }
  }
}
</script>

<style scoped>

</style>
